<template>
  <div id="table-panel" style="width: 100%">
    <!-- 表格组件 -->
    <div class="content-main" :class="{ 'open-new-line': newLine }">
      <!-- showTable -->
      <el-table
        ref="tablePanel"
        :data="tableDataList"
        row-key="id"
        default-expand-all
        v-loadmore="loadMore"
        :border="true"
        aria-activedescendant=""
        v-loading="pictLoading"
        :show-overflow-tooltip="true"
        @select-all="selectAll"
        @selection-change="selectionChange"
        @select="select"
        @toggleSelection="clearSelection"
        :empty-text="$t('label.dashboard.noRecord')"
        :style="{
          width: '100%',
          'min-height': minHeight ? '418px' : '0',
          'max-height': '418px',
        }"
      >
        <el-table-column
          lable=""
          type="index"
          width="50"
          :show-overflow-tooltip="true"
          v-if="sequenceFlg && tableAttr.length !== 0"
        >
          <template slot="header">
            <svg
              class="icon admin_s"
              aria-hidden="true"
              ref="iconTool"
              id="iconTool"
            >
              <use href="#icon-gl"></use>
            </svg>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          v-if="sequenceFlg && tableAttr.length !== 0"
          type="selection"
          width="50"
        >
        </el-table-column>
        <template v-for="item in tableAttr">
          <slot :item="item">
            <el-table-column
              :label="item.labelname"
              :width="item.colwidth ? item.colwidth : ''"
              :render-header="renderheader"
              :show-overflow-tooltip="true"
            >
              <!-- 自定义表头 -->
              <template slot="header">
                <div class="tableHeadField">
                  {{ item.labelname }}
                </div>
              </template>
              <!-- 自定义内容 -->
              <template slot-scope="scope">
                <div
                  v-if="['url'].includes(item.apiname)"
                  class="tableOverFild"
                >
                  <a class="tableOverFild" :href="scope.row[item.apiname]">{{
                    scope.row[item.apiname]
                  }}</a>
                </div>
                <div
                  v-else-if="'isactivity'.includes(item.apiname)"
                  class="tableOverFild"
                >
                  <!-- 更改判断 -->
                  {{
                    scope.row[item.apiname] !== "true"
                      ? $t("label.tabpage.noz")
                      : $t("label.checkbox.true")
                  }}
                </div>
                <div
                  v-else-if="['name'].includes(item.apiname)"
                  class="tableOverFild"
                >
                  <a @click="viewSourceCode(scope.row)">{{
                    scope.row[item.apiname]
                  }}</a>
                </div>
                <div v-else class="tableOverFild">
                  {{ scope.row[item.apiname] }}
                </div>
              </template>
            </el-table-column></slot
          ></template
        >

        <!-- 操作列 -->
        <el-table-column
          :show-overflow-tooltip="true"
          align="center"
          v-if="tableAttr.length !== 0"
        >
          <template slot-scope="scope">
            <el-popover
              placement="bottom-end"
              popper-class="pop_small"
              trigger="hover"
              :visible-arrow="false"
            >
              <svg
                class="icon pull-right"
                aria-hidden="true"
                style="cursor: pointer; width: 20px; border-radius: 3px"
                slot="reference"
              >
                <use href="#icon-xiala"></use>
              </svg>
              <ul>
                <li
                  v-for="(btnItem, index) in operationButtonListFilter(
                    scope.row
                  )"
                  :key="btnItem.action"
                  @click="handleTableAction(scope, btnItem)"
                >
                  <span class="cursor-pointer" :key="index">
                    {{ btnItem.label }}
                  </span>
                </li>
              </ul>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import * as CommonObjApi from "../../../api";

export default {
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
          let sign = 100;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value();
          }
        });
      },
    },
  },
  components: {
    // EditableCell,
    // draggable,
    // oneShuttle
  },
  props: {
    //序列
    sequenceFlg: {
      type: Boolean,
      defalt: false,
    },
    // 列表数据
    tableDataList: {
      type: Array,
      defalt: [],
    },
    // 使用自定义表头
    customHeader: {
      type: Boolean,
      defalt: true,
    },
    pageNum: {
      type: Number,
      defalt: 1,
    },
    id: {
      type: String, // 每一列数据的 id
      default: "id",
    },
    stripe: {
      type: Boolean,
      default: false,
    },
    // 当前视图是否可编辑
    viewModify: {
      type: String,
    },
    // 是否提示无更多数据
    noDataTip: {
      type: Boolean,
      default: false,
    },
    // 是否开启分页
    pagination: {
      type: Boolean,
      default: true,
    },
    pagerCount: {
      type: Number,
      default: 7,
    },
    // 表格体数据
    pageObj: {
      type: Object,
      default: null,
    },
    pageSelected: {
      type: Boolean,
      default: true,
    },
    // 可跳转链接是否使用a标签
    useHyperlinks: {
      type: Boolean,
      default: true,
    },
    showTableHeadTool: {
      type: Boolean,
      default: true,
    },
    // 表头
    tableAttr: {
      type: Array,
      default: () => [],
    },
    viewSelectedFieldList: {
      type: Array,
      dafault: () => [],
    },
    viewUnselectedFieldList: {
      type: Array,
      dafault: () => [],
    },
    // 数据排序
    sortable: {
      type: Boolean,
      default: false,
    },
    // 选中框
    checked: {
      type: Boolean,
      default: false,
    },
    // 按钮
    tableButton: {
      // 按钮
      type: [Array, Object],
      default: () => [],
    },
    // 操作按钮控制函数
    tableButtonCtrl: {
      type: Function,
      default: undefined,
    },
    // 表格高度
    tableHeight: {
      type: [String, Number, undefined], // 表格高度
      default: undefined,
    },
    border: {
      type: Boolean, // 是否开启边框
      default: false,
    },
    checkedCount: {
      type: Number, // 选中数量
      default: 0,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    // 是否开启序号
    showIndex: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: [Function, undefined], // 单选框是否可以选中
      default: undefined,
    },
    selectedList: {
      type: Array, // 已选中列
      default: () => [],
    },
    autoChecked: {
      // 根据传入的id自动选中
      type: Function,
    },
    spanMethod: {
      // 合并列算法
      type: [Function, undefined],
      default: undefined,
    },
    memory: {
      type: Boolean, // 是否开启分页记忆选中功能 现版本配合vuex使用
      default: false,
    },
    headerRowClassName: {
      type: [Function, String],
      default: undefined,
    },
    rowClassName: {
      type: [Function, undefined],
      default: undefined,
    },
    newLine: {
      // 是否展开新行
      type: Boolean,
      default: false,
    },
    minHeight: {
      // 是否设置min-height
      type: Boolean,
      default: false,
    },
    // 对象id，判断对象是否有编辑、删除权限
    objId: {
      type: String,
      default: "",
    },
    prefix: {
      type: String,
      default: "",
    },
    // 视图id
    viewId: {
      type: String,
      default: "",
    },
    // 使用父组件传来的objectApi，父组件使用路由传递的值
    objectApi: {
      type: String,
      default: "",
    },
    showLock: {
      // 是否显示锁定功能
      type: Boolean,
      default: true,
    },
    operationButtonList: {
      type: Array,
      defalt: [],
    },
  },
  data() {
    return {
      pictLoading: false,
      showSearchTable: false,
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      fieldId: "",
      multiChecked: false,
      relevantFieldId: "",
      relevantObjId: "",
      relevantObjApi: "",
      relevantPrefix: "",
      relevantObjectApi: "",
      countryCode: this.$cookies.get("countryCode"),
      showEditTableFieldPopover: false,
      skeletonOptions: {
        active: true,
        row: 18,
        column: 5,
        width: "18vw",
        height: "20px",
        justifyContent: "space-around",
      },
      schemefieldLength: 0, // 评分字段最大分值
      searchField: "",
      errTip: this.$i18n.t("label.vlidaterule.message"),
      field: "", // 要修改的字段名称
      changeAll: false, // 是否应用于全部选中数据
      editableCellOptions: [],
      inputType: "", // 要修改数据显示类型
      toChangeValue: null, //要修改的数据
      dialogVisible: false,
      editModeEnabled: false,
      origin: -1, // 选中数据起点
      pin: false, // 是否按住shift键
      memoryCheckList: [],
      pageSize: [10, 20, 50, 100],
      showTable: false,
      pageInfo: true,
      checkedList: [],
      columnTooltip: true,
      isSelectList: [],
      selected: [],
      selection: [],
      total: 0, // 数据总条数
      showApply2Others: false,
      namaLableList: [],
      unselectedFieldList: [],
      allSelectedFieldList: [],
      editableCellAttr: null, //单元格编辑字段信息
      dataId: "",
      selectedFieldList: this.viewSelectedFieldList,
      customColors: [
        { color: "#70acf1", percentage: 20 },
        { color: "#f7c97e", percentage: 40 },
        { color: "#faa58c", percentage: 100 },
      ],
      precision: 0,
      min: 0,
      max: 0,
      objModifyAll: null, // 管理员权限
      objModify: null, // 对象编辑权限
      objDelete: null, // 对象删除权限
      userUrl: "",
      token: this.$CCDK.CCToken.getToken(),

      inlineedit: false, // 全局内联编辑权限
      echoSelected: [],
      index: 1,
      str: "",
      projectId: "",
    };
  },
  created() {
    this.userUrl = this.$cookies.get("domainName");
    this.memoryCheckList = this.memoryCheckList.concat(this.selectedList);
    // 遍历索引，赋值给data数据
    // this.dataList.forEach((item, index) => {
    //   item.index = index
    // })
    this.getLeadHeat();
    this.inlineedit = localStorage.getItem("inlineedit");
  },
  mounted() {
    // 如果表格宽度小于847  分页部分将折行显示 逻辑控制隐藏左边部分
    if (document.getElementById("table-panel").offsetWidth < 847) {
      this.pageInfo = false;
    }
    this.showTable = true;

    // 获取键盘事件
    window.addEventListener("keydown", this.keydownFn);
    window.addEventListener("keyup", this.keyupFn);

    this.getObjectPermission(this.objId);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keydownFn);
    window.removeEventListener("keyup", this.keyupFn);
  },
  methods: {
    keydownFn(code) {
      // 获取键盘按住事件
      // 判断是否按住shift键，是就把pin值赋为true
      if (code.keyCode === 16 && code.shiftKey) {
        this.pin = true;
      }
    },
    keyupFn(code) {
      // 获取键盘按住事件
      // 判断是否松开shift键，是就把pin值赋为true
      if (code.keyCode === 16) {
        this.pin = false;
      }
    },
    // 自定义表头 tooltip
    renderheader(h, { column }) {
      // let flg
      // if (column.realWidth > column.minWidth) {
      //   flg = true
      // } else {
      //   if (column.width > column.minWidth) {
      //     flg = true
      //   } else {
      //     flg = false
      //   }
      // }
      let header = h(
        "el-tooltip",
        {
          props: {
            disabled: false,
            content: column.label,
            placement: "top",
            effect: "dark",
          },
        },
        [
          h(
            "span",
            {
              on: {
                click: function () {},
              },
              class: "header",
            },
            column.label
          ),
        ]
      );
      return header;
    },
    viewSourceCode(row) {
      this.$emit("viewSourceCode", row);
    },
    checkboxSelect(row) {
      if (
        row.their_task &&
        window.location.href.substring(window.location.href.length - 3) == "p03"
      ) {
        return false; // 禁用
      } else {
        return true; // 不禁用
      }
    },
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.token}`;
    },
    // 潜在客户热度设置信息
    getLeadHeat() {
      if (localStorage.getItem("coldLevel") === null) {
        CommonObjApi.getLeadHeat({}).then((res) => {
          localStorage.setItem("coldLevel", Number(res.data.coldLevel) + 1);
          localStorage.setItem("hotLevel", Number(res.data.hotLevel) + 1);

          this.customColors[0].percentage = localStorage.getItem("coldLevel");
          this.customColors[1].percentage = localStorage.getItem("hotLevel");
        });
      } else {
        this.customColors[0].percentage = localStorage.getItem("coldLevel");
        this.customColors[1].percentage = localStorage.getItem("hotLevel");
      }
    },
    // 获取对象权限
    getObjectPermission(objId) {
      if (objId !== null && objId !== "") {
        CommonObjApi.getObjectPermission({ id: objId }).then((res) => {
          this.objModifyAll = res.data.modifyAll || false;
          this.objModify = res.data.modify || false;
          this.objDelete = res.data.delete || false;
        });
      } else {
        this.objModifyAll = false;
        this.objModify = false;
        this.objDelete = false;
      }
    },
    // 查找/查找多选
    remoteSearch() {
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    // 设置查找筛选条件
    setFieldReltaion() {
      let values = [];
      values.push(this.id);
      // for (let reltaion in filterFieldIds) {
      //   let attr = this.formAttr.find((item) => {
      //     return item.fieldId === filterFieldIds[reltaion].fieldid
      //   })
      //   if (
      //     attr !== undefined &&
      //     this.formData[attr.prop] !== null &&
      //     Array.isArray(this.formData[attr.prop])
      //   ) {
      //     values.push(this.formData[attr.prop].join(';'))
      //   } else if (attr !== undefined && this.formData[attr.prop] !== null) {
      //     values.push(this.formData[attr.prop])
      //   }
      // }
      // this.$refs.searchTable.filterConditionVals = values
    },
    beforeClose() {
      this.showSearchTable = false;
    },
    // 下拉框类型字段显示值处理
    showSelectVal(item, scope) {
      let showContnet = "";
      let option =
        item.options &&
        item.options.find((option) => {
          return option.codekey === scope.row[item.schemefieldName];
        });
      if (option !== undefined) {
        showContnet = option.codevalue;
      } else {
        showContnet = scope.row[item.schemefieldName] || "";
      }
      return showContnet;
    },
    // 保存视图选中字段
    saveSelectedField() {
      this.$refs.oneShuttle.curGroupLists();
      this.selectedFieldList = this.$refs.oneShuttle.viewSelectedFieldList;
      this.$nextTick(() => {
        if (this.selectedFieldList.length > 15) {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_view_to_display_fields")
          );
        } else if (this.selectedFieldList.length === 0) {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_view_notice_atleast1field")
          );
        } else {
          this.showEditTableFieldPopover = false;
          this.$emit("saveSelectedField", this.str);
          this.selectedFieldList = [];
          // scope._self.$refs[`popover-${scope.$index}`].doClose();
        }
      });
      // let ids = [];
      // this.selectedFieldList.forEach((item) => {
      //   ids.push(item.id);
      // });
      // if (this.selectedFieldList.length > 10) {
      //   this.$message.warning(this.$i18n.t('vue_label_commonobjects_view_to_display_fields'));
      // } else if (this.selectedFieldList.length === 0) {
      //   this.$message.warning(this.$i18n.t('vue_label_commonobjects_view_notice_atleast1field'));
      // } else {
      //   this.showEditTableFieldPopover = false
      //   this.$emit("saveSelectedField", ids.toString());
      //   // scope._self.$refs[`popover-${scope.$index}`].doClose();
      // }
    },
    // 查询视图详细信息
    searchViewDetail() {
      this.$emit("searchViewDetail");
    },
    // 修改排序字段
    changeSortFiled(field) {
      if (event.target.tagName !== "I") {
        this.$emit("changeSortFiled", field);
      }
    },
    // 日期格式化
    formatDate(date) {
      let dateStr = "";
      let dateRes = new Date(date);
      const Y = dateRes.getFullYear();
      const M =
        dateRes.getMonth() + 1 < 10
          ? "0" + (dateRes.getMonth() + 1)
          : dateRes.getMonth() + 1;
      const D = dateRes.getDate();
      if (isNaN(date) && !isNaN(Date.parse(date))) {
        dateStr = `${Y}/${M}/${D}`;
      }
      return dateStr;
    },
    // 日期时间格式化
    formatDateTime(date) {
      let dateStr = "";
      let dateRes = new Date(date);
      const Y = dateRes.getFullYear();
      const M =
        dateRes.getMonth() + 1 < 10
          ? "0" + (dateRes.getMonth() + 1)
          : dateRes.getMonth() + 1;
      const D = dateRes.getDate();
      const h =
        dateRes.getHours() < 10 ? `0${dateRes.getHours()}` : dateRes.getHours();
      const m =
        dateRes.getMinutes() < 10
          ? `0${dateRes.getMinutes()}`
          : dateRes.getMinutes();
      const s =
        dateRes.getSeconds() < 10
          ? `0${dateRes.getSeconds()}`
          : dateRes.getSeconds();
      if (isNaN(date) && !isNaN(Date.parse(date))) {
        dateStr = `${Y}/${M}/${D} ${h}:${m}:${s}`;
      }
      return dateStr;
    },
    // 自动换行
    wordWrap() {
      this.$emit("wordWrap", ...arguments);
    },
    // 锁定
    locked(item) {
      // 关闭锁定气泡
      this.$refs[`${item.schemefieldName}-popover`][0].doClose();
      this.$refs[`${item.schemefieldName}-popover`][1].doClose();
      this.$emit("locked", ...arguments);
    },
    // 文件下载
    downLoadFile(file) {
      let baseURL = this.$baseConfig.baseURL;
      let token = this.$CCDK.CCToken.getToken();
      var link = document.createElement("a");
      link.setAttribute("download", "");
      //相关列表需要的文件下载
      if (this.objId && this.objId === "attachement") {
        link.href = `${baseURL}/file/downloadAttachement?accessToken=${token}&id=${file.id}`;
      } else {
        //视图列表的文件下载
        link.href = `${baseURL}/file/downloadFile?accessToken=${token}&id=${
          file.fileid || file.file_info_id || file.id
        }`;
      }
      link.click();
    },
    // 名称字段跳转路径
    nameHrefUrl(row) {
      return row.id ? `#/commonObjects/detail/${row.id}/DETAIL` : "";
    },
    // 所有人字段跳转路径
    hrefUrl() {
      // // 项目管理-实际工作清单点击日期跳转详情
      // if (this.$cookies.get('activeTabObjId') === 'projectworklist' && item.schemefieldType === "D") {
      //   return row[item.schemefieldName] ? `#/commonObjects/detail/${row.id}/DETAIL` : ''
      // } else {
      //   return row[item.schemefieldName] ? `#/commonObjects/detail/${row[item.schemefieldName]}/DETAIL` : ''
      // }
    },
    // url跳转
    jumpToUrl(url) {
      window.open(url);
    },
    // 进度条格式化
    formatProgress() {
      return "";
    },
    // 表格滚动懒加载
    append() {
      // this.loading = true
      this.$emit("append");
      // this.loading = false
      // this.loading = true
      // this.$http[this.url]({
      //   ...params,
      //   offset: this.offset,
      //   limit: this.limit
      // })
      //   .then(result => {
      //     this.tableData = [
      //       ...this.tableData,
      //       ...result.data.rows
      //     ]
      //
      //     this.total = result.data.total || 0
      //   })
      //   .finally(() => {
      //     this.loading = false
      //   })
    },
    reload() {
      // 刷新重置 offset 和表格
      this.params.offset = 0;
      this.tableData = [];

      // api动态加载完 开始重新请求数据
      this.$nextTick(() => {
        this.init(this.params);
      });
    },
    // 节流函数
    fnThrottle(method, delay, duration) {
      var that = this;
      var timer = this.timer;
      var begin = new Date().getTime();
      return function () {
        var current = new Date().getTime();
        clearTimeout(timer);
        if (current - begin >= duration) {
          method();
          begin = current;
        } else {
          that.timer = setTimeout(function () {
            method();
          }, delay);
        }
      };
    },
    // 滚动事件
    loadMore() {
      let that = this;
      // let dom = document.querySelector('.el-table__body-wrapper')
      let dom = document.getElementsByClassName("el-table__body-wrapper")[
        document.getElementsByClassName("el-table__body-wrapper").length - 1
      ];
      dom.addEventListener("scroll", function () {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        // 解决表格滚动时左右对不齐问题
        if (scrollDistance <= 30) {
          that.$refs.tablePanel.doLayout();
        }

        if (scrollDistance <= 0) {
          //等于0证明已经到底，可以请求接口
          if (!this.noDataTip) {
            that.fnThrottle(that.append, 500, 1000)();
          }
        }
      });
    },
    // 表格滚动懒加载
    clearSelection() {
      //   if (rows) {
      //     rows.forEach((row) => {
      //       this.$refs.tablePanel.toggleRowSelection(row);
      //     });
      //   } else {
      //     this.$refs.tablePanel.clearSelection();
      //   }
    },
    clearSelect() {
      this.selected = [];
    },
    // 拖拽开始事件
    startDrag() {
      return false;
    },
    // 表头操作
    handleAction(index, row, action) {
      if (arguments.length === 1) {
        action = index;
        this.$emit(action, action);
      } else {
        if (action === "handleDel") {
          this.$confirm(
            "此操作将永久删除该文件, 是否继续?",
            this.$i18n.t("label.prompt"),
            {
              confirmButtonText: this.$i18n.t("label_confirm"),
              cancelButtonText: this.$i18n.t("label_cancel"),
              type: "warning",
            }
          )
            .then(() => {
              this.$emit(action, index, row);
            })
            .catch(() => {
              // ;
            });
        } else {
          this.$emit(action, index, row);
        }
      }
    },
    // 按钮权限判断
    ifButtonShow(rowData, btn) {
      if (
        btn.label === this.$i18n.t("pagecreator_page_button_edit") &&
        (this.objModifyAll || this.objModify)
      ) {
        return true;
      } else if (
        btn.label === this.$i18n.t("component_setup_tabs_label_delete") &&
        (this.objModifyAll || this.objDelete)
      ) {
        return true;
      } else if (
        btn.label !== this.$i18n.t("pagecreator_page_button_edit") &&
        btn.label !== this.$i18n.t("component_setup_tabs_label_delete") &&
        (this.objModifyAll || this.objModify)
      ) {
        return true;
      } else {
        return false;
      }
    },
    // 列表中操作
    handleTableAction(scope, btnItem) {
      let id = scope.row.id;
      if (btnItem.action == "activeMember") {
        // 点击活动成员跳转到活动成员页面
        this.$emit("activeMember", scope.row);
      } else {
        this.$emit(btnItem.action, id);
      }
    },
    // 自定义序号序列化
    indexMethod(idx) {
      idx += 1;
      let page = 0;
      if (this.pageObj.page && this.pageObj.limit) {
        page = (this.pageObj.page - 1) * this.pageObj.limit;
      }
      return page + idx;
    },
    // 显示页数发生变化时
    handleSizeChange(val) {
      this.pageObj.handleSizeChange(val);
      this.$emit("handleSizeChange", val);
    },
    // 当前页码发生变化时
    handleCurrentChange(val) {
      this.pageObj.handleCurrentChange(val);
      // 分页记录选中
      this.$emit("handleCurrentChange", val);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 内联编辑内容改变
    handleChange() {
      this.$emit("handleChange", ...arguments);
    },
    // 跳转事件
    jumpTo(item, scope, cb) {
      if (event.target.tagName !== "IMG") {
        let idx = scope.$index + 1;
        let rowData = scope.row;
        let emitData = {
          field: item,
          index: idx, // 当前点击位置
          data: rowData, // 当前点击数据
        };
        this.$emit(cb, emitData);
      }
      // cb && cb(scope)
    },
    // 单选事件
    select(selection, row) {
      const data = this.$refs.tablePanel.tableData; // 获取所有数据
      const origin = this.origin; // 起点数 从-1开始
      const endIdx =
        selection[selection.length - 1] &&
        selection[selection.length - 1].index; // 终点数

      // 判断设置成选中还是未选中
      this.checkedList = selection;
      if (this.pin && selection.includes(data[origin])) {
        // 判断按住
        const sum = Math.abs(origin - endIdx) + 1; // 这里记录终点
        const min = Math.min(origin, endIdx); // 这里记录起点
        let i = 0;
        while (i < sum) {
          const index = min + i;
          let checked = this.checkedList.find((checked) => {
            return checked.id === data[index].id;
          });

          if (checked === undefined) {
            this.checkedList.push(data[index]);
          }
          this.$refs.tablePanel.toggleRowSelection(data[index], true); // 通过ref打点调用toggleRowSelection方法，第二个必须为true
          i++;
        }
      } else {
        //
        this.origin = row.index; // 记录起点
      }
    },
    // 全选事件
    selectAll(selection) {
      this.$emit("onchange", selection);
      // // 如果全选 将全部列表元素插入到已选列表 否则全部删除列表
      // let copyArr = selection;
      // // selection = this.pageObj.dataList
      // selection = this.dataList;
      // selection.forEach((row) => {
      //   this.handlerChecked(row, !emptyArray(copyArr));
      // });
      // // 可能修改: 在调用getCheckedList时返回this.checkedList,为了在全选时也能使用
      // this.checkedList =
      //   this.checkedList.length === this.pageObj.dataList.length
      //     ? []
      //     : selection;

      // this.handleSelect(selection);
    },
    // handleSelect(val) {
    //   const hasSave = this.selected.find((item) => {
    //     return item.page === this.pageObj.page;
    //   });
    //   if (hasSave) {
    //     // hasSave.rows = this.pageObj.dataList.filter(item => {
    //     //   return val.includes(item)
    //     // })
    //     hasSave.rows = this.dataList.filter((item) => {
    //       return val.includes(item);
    //     });
    //   } else {
    //     this.selected.push({
    //       page: this.pageObj.page,
    //       rows: val,
    //     });
    //   }
    // },
    // 处理全选反选中的冗余代码
    // handlerChecked(row, isNotAllCheck) {
    //   if (row.id) {
    //     let id = row.id;
    //     let idx = this.memoryCheckList.indexOf(id);
    //     // 如果不存在 加入
    //     if (idx === -1 && !isNotAllCheck) {
    //       this.memoryCheckList.push(id);
    //     }
    //     // 如果存在 直接删除
    //     if (idx > -1 && isNotAllCheck) {
    //       this.memoryCheckList.splice(idx, 1);
    //     }
    //   }
    // },
    // sortBy(props) {
    //   return function (a, b) {
    //     return a[props] - b[props];
    //   };
    // },
    // 表格选中状态
    selectionChange(rows) {
      this.$emit("onchange", rows);
      this.$refs.tablePanel.tableData;
    },
    // 获取被选中列表
    // getCheckedList() {
    //   // 如果开启分页记录 memorycheckList中的所有数据
    //   if (this.memory) {
    //     // 将当前页的数据保存 memorycheckList中 防止bug
    //     return this.memoryCheckList;
    //   } else {
    //     return this.checkedList;
    //   }
    // },
    // clearMemoryList() {
    //   this.memoryCheckList = [];
    // },
    toggleSelection(rows) {
      // if (this.memory) {
      //   this.$refs['tablePanel'].clearSelection()
      //   const target = this.selected.find(item => {
      //     return item.page === this.pageObj.page
      //   })
      //   if (!target) return
      //   const rows = target.rows
      //   if (rows && rows.length > 0) {
      //     this.$nextTick(() => {
      //       rows.forEach(row => {
      //         this.dataList.forEach(item => {
      //           if (item.memId === row.memId) {
      //             this.$refs['tablePanel'].toggleRowSelection(item)
      //           }
      //         })
      //       })
      //     })
      //   }
      // }

      if (rows) {
        rows.forEach((row) => {
          this.$refs.tablePanel.toggleRowSelection(row);
        });
      } else {
        this.$refs.tablePanel.clearSelection();
      }
    },
    getSelected() {
      if (this.selected.length === 0) {
        return [];
      }
      let result = [];
      this.selected.forEach((item) => {
        result = [...result, ...item.rows];
      });
      return result;
    },
    echo(val) {
      this.echoSelected = val;
    },
    unique(arr) {
      for (let i = 0; i < arr.length; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[i].id === arr[j].id) {
            arr.splice(j, 1);
            j = j - 1;
          }
        }
      }
      return arr;
    },
    memberFun(val) {
      this.str = val;
    },
    /**
     * 删除按钮显示条件: isactivity == false || 登录用户简档是系统管理员(aaa000001)
     * 编辑按钮显示条件: isactivity == false || 登录用户简档是系统管理员(aaa000001) || 登陆用户是市场活动记录所有人
     * 邀约码按钮显示条件: isusing ==true (是邀约成员)
     * 二维码按钮显示条件: 无判断限制，都可见
     * 活动成员按钮显示条件: 无判断限制，都可见
     */
    operationButtonListFilter(currentVal) {
      const { isactivity, campaignownerid, isusing } = currentVal;
      const { profileId, userId } = this.$store.state.userInfoObj;
      let operationButtonList = this.operationButtonList;

      operationButtonList = operationButtonList.filter((item) => {
        if (item.action === "delete") {
          if (isactivity === "false" || profileId === "aaa000001") {
            return true;
          } else {
            return false;
          }
        } else if (item.action === "edit") {
          if (isactivity === "false" || profileId === "aaa000001"|| userId === campaignownerid) {
            return true;
          } else {
            return false;
          }
        } else if(item.action === "invitationCode") {
          if (isusing === "true") {
            return true;
          } else {
            return false;
          }
        } else if (item.action === "qrCode") {
          return true;
        } else if (item.action === "activeMember"){
          return true;
        } else {
          return true;
        }
      });
      return operationButtonList;
    },
  },
  computed: {
    userCheckedCount() {
      return this.memory ? this.selectedList.length : this.checkedList.length;
    },
  },
  watch: {
    searchField(nval) {
      setTimeout(() => {
        if (nval === "") {
          this.unselectedFieldList = this.allSelectedFieldList;
        } else {
          this.unselectedFieldList = this.allSelectedFieldList.filter(
            (item) => {
              return item.label.indexOf(nval) !== -1;
            }
          );
        }
      }, 100);
    },
    unselectedFieldList: {
      deep: true,
      handler(nval) {
        nval.length > 0 &&
          nval.forEach((field) => {
            let isExist = false;
            this.allSelectedFieldList.forEach((item) => {
              if (item.id === field.id) {
                isExist = true;
              }
            });
            if (!isExist) {
              this.allSelectedFieldList.push(field);
            }
          });
      },
    },
    selectedFieldList: {
      deep: true,
      handler(nval) {
        nval.length > 0 &&
          nval.forEach((field) => {
            this.allSelectedFieldList = this.allSelectedFieldList.filter(
              (item) => {
                return item.id !== field.id;
              }
            );
            //
          });
      },
    },
    viewSelectedFieldList: {
      handler(nval) {
        this.selectedFieldList = nval;
      },
      deep: true,
    },
    pageObj: {
      // 分页数据发生改变时候选中选项框
      handler: function (oldVal, newVal) {
        // 定时器确保表格已经刷新
        setTimeout(() => {
          // 数据更新
          this.dataList = this.pageObj.dataList;
          this.dataList &&
            this.dataList.forEach((item, index) => {
              item.index = index;
            });
          // 打印传递数据
          // Bus.$emit('printList',this.dataList)
          this.$nextTick(() => {
            //解决第一次触发emit无效问题
            this.$store.state.printList = this.dataList;
          });

          // 创建选中
          if (!newVal || !newVal.dataList) {
            return false;
          }
          // if (this.selectedList && this.selectedList.length !== 0) {
          // 调用自定义选中事件
          // if (this.autoChecked) {
          //   // 当前选中列 当前数据源
          //   let indexList = []
          //   // 如果开启记忆功能 且当前页中有用户选项时 选中当前页
          //   if (this.memory && this.memoryCheckList) {
          //     indexList = this.autoChecked(this.memoryCheckList, newVal.dataList)
          //   } else {
          //     indexList = this.autoChecked(this.selectedList, newVal.dataList)
          //   }
          //   // 选中列
          //   indexList.forEach((v, k) => {
          //     this.$refs.tablePanel && this.$refs.tablePanel.toggleRowSelection(newVal.dataList[v])
          //   })
          // }

          // 选中列
          // indexList.forEach((v, k) => {
          //   this.$refs.tablePanel && this.$refs.tablePanel.toggleRowSelection(newVal.dataList[v])
          // })
          // }

          // 记录上次选中数据
          // TODO:修改选中之前的数据状态
          newVal.dataList.forEach((item) => {
            if (item.checked !== undefined) {
              this.$refs.tablePanel.toggleRowSelection(item);
            }
          });
        }, 20);
      },
      deep: true,
    },
    viewUnselectedFieldList: {
      handler(nval) {
        this.allSelectedFieldList = nval;
        this.unselectedFieldList = nval;
      },
      deep: true,
    },
    objId(nVal) {
      this.getObjectPermission(nVal);
    },
    // echoSelected (val) {
    //   const data = this.$refs.tablePanel.tableData;
    //   if (data.length !== 0) {
    //     data.forEach(item => {
    //       val.forEach(items => {
    //         if (item.id === items.id) {
    //
    //
    //           this.$nextTick(() => {
    //             this.$refs.tablePanel.toggleRowSelection(item, true)
    //           })
    //         }
    //       })
    //     })
    //   }
    // },
  },

  // updated () {
  //   if (this.echoSelected.length !== 0) {
  //     const data = this.$refs.tablePanel.tableData;
  //     // ;
  // data.forEach(item => {
  //   this.echoSelected.forEach(items => {
  //     if (item.id === items.id) {
  //       setTimeout(() => {
  //         this.$refs.tablePanel.toggleRowSelection(item, true)
  //       }, 500)
  //     }
  //   })
  // })

  //   }

  // }
};
</script>

<style lang="scss" scoped>
.icon {
  display: inline-block;
}
.content-main {
  position: relative;
  clear: both;
}

.showEditTableField-box {
  position: absolute;
  top: 11px;
  left: 18px;
  z-index: 100;
}

.action-btn {
  color: #006dcc !important;
}

// 评分字段显示
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  color: #ccc;
  float: left;
  display: contents;
}

.stars span {
  font-size: 20px;
  margin-left: 3px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

.table-head-tool {
  .el-input {
    position: absolute;
    top: -3px;
    left: -10px;
    margin-left: 10px;
    // width: calc(100% - 40px);
  }

  ::v-deep .el-input__inner {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    height: 25px;
    padding-left: 0;
  }

  .dragBox {
    width: 49%;
  }

  .item-box-left-container {
    border: 1px solid #ddd;
    border-radius: 4px;
    min-height: 250px;
    max-height: 250px;

    .searchBox {
      position: relative;
      padding: 0 10px;
      top: 5px;
      height: 25px;
      margin-bottom: 0;

      i {
        position: absolute;
        z-index: 100;
      }

      ::v-deep .el-input__inner {
        padding-left: 32px;
      }
    }
  }

  .itemBox {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 5px 0;
    min-height: 250px;
    max-height: 250px;
    overflow: auto;
  }

  .itemBox-left {
    border: none;
    min-height: 200px;
    max-height: 222px;
    margin-top: 2px;
  }

  .dragItem {
    width: 100%;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    padding: 0 10px;
    cursor: move;

    &:hover {
      background: #fafaf9;
    }
  }

  .btns {
    width: 95%;
    margin: 10px 15px 10px 0;
    text-align: right;
    position: absolute;
    bottom: 0;
  }
}

// 进度条样式修改
::v-deep .el-progress-bar {
  padding-right: 14px;
}

.fileItem {
  color: #489ad9;
  cursor: pointer;
  margin-right: 5px;
}

::v-deep .el-dialog__header {
  text-align: left;
}

::v-deep .el-table-column--selection .cell {
  text-align: center;
}

// ::v-deep .el-table__fixed-body-wrapper {
//    top: 48px !important;
// }

::v-deep .el-dialog__footer {
  clear: both;
}

// 表格纵向滚动条
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow: auto;
}
.tableOverFild {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableHeadField {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;
  .popover-span {
    position: absolute;
    right: 0;
  }
}

// 提示语
.noDataTip {
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  background: #fff;
}

// 表头样式
.el-table--border,
.el-table--group {
  border: 1px solid #dedcda;
}

::v-deep .el-table th.is-leaf {
  color: #666666;
  border-bottom: 1px solid #dedcda;
  font-size: 12px;
}

::v-deep .el-table--border th {
  border-right: 1px solid #dedcda;
}

::v-deep .el-table__header tr th {
  padding: 8px;
  padding-left: 0;
  padding-right: 0;
}

// 标签样式
.tag {
  font-size: 12px;
  padding: 5px;
  height: 25px;
  line-height: 15px;
  margin-right: 5px;
}

::v-deep .el-tag--dark {
  background-color: #62a5f3;
  border-color: #62a5f3;
}

::v-deep .el-tag--dark.el-tag--info {
  background-color: #dedcda;
  border-color: #dedcda;
}

::v-deep .el-tag--dark.el-tag--danger {
  background-color: #f6817b;
  border-color: #f6817b;
}

::v-deep .el-tag--dark.el-tag--success {
  background-color: #7cedcc;
  border-color: #7cedcc;
}

::v-deep .el-tag--dark.el-tag--warning {
  background-color: #fec366;
  border-color: #fec366;
}

// 进度条样式
.el-progress {
  display: inline;
}

::v-deep .el-table td .cell {
  padding-right: 20px;
}
::v-deep .el-table th .cell {
  font-size: 12px;
}

::v-deep .el-table td:first-child .cell {
  padding-right: 14px;
}

// 表头颜色自定义、单元格超出...显示
::v-deep .el-table__header {
  img {
    margin-top: -4px;
  }

  tr th {
    background: #fafaf9;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th .cell {
    padding-right: 14px;

    .el-checkbox {
      margin-left: 0;
    }
  }
}

.admin_s {
  margin-top: -4px;
  cursor: pointer;
  width: 14px;
}

::v-deep .el-table .cell {
  min-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  // padding-right: 20px;
}

// #table-panel .content-main .el-table {
//   tr th {
//     background: #006dcc;
//   }
// }

::v-deep .el-table td {
  font-size: 12px;
  position: relative;
  padding: 9px 0;
  color: #080707;
  border-bottom: 1px solid #dedcda;
}

::v-deep .el-table--border td {
  border-right: none;
}

.allow-click {
  color: #006dcc;
  text-decoration: solid;
}
::v-deep .is-checked {
  .el-checkbox__input {
    .el-checkbox__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
  }
}
::v-deep .el-checkbox {
  .is-indeterminate {
    .el-checkbox__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
  }
}
</style>
<style scoped>
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
}
.pop_small li span {
  padding-left: 10px;
}
.pop_small li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
</style>
